<template>
  <div
      class="flex flex-row w-full items-center text-a-neutral-darkest shadow-sm"
  >
    <div
        class="flex flex-col w-6/12 md:w-5/12 relative md:border-none"
    >
      <ClassifiedInstantSearchQuerySection
          ref="querySection"
          @searchBySelectedCategory="
          (category) =>
            searchBySelectedCategory(
              category
            )
        "
      />
    </div>
    <div
        class="text-sm hidden md:flex flex-row items-center justify-center text-center w-2/12 border border-a-neutral-dim border-l-0 border-r-0 bg-white h-10 text-a-neutral-lightest"
    >
      <span
          class="font-semibold"
      >Around</span
      >
      <Icon name="mdi:map-marker"/>
    </div>
    <div
        class="flex flex-col w-6/12 md:w-4/12 relative border-l md:border-none"
    >
      <InstantSearchLocationSection
          ref="locationSectionRef"
          @searchWithNewlySetLocation="
          searchBySelectedLocation
        "
          @searchFromLocation="
          gotoSearchPage()
        "
      />
    </div>
    <BaseButton
        primary-color-class="a-primary"
        width-class="w-2/12 rounded-r rounded-l-none h-10"
        text-color-class="text-a-neutral-darkest"
        @click.native="gotoSearchPage()"
        :loading="isSearching"
        loadingColor="#000000"
        loadingSize="7px"
    >
      <Icon
          name="mdi:magnify"
      />
    </BaseButton>
  </div>
</template>

<script
    setup
    lang="ts"
>
import type {InstantSearchLocationSection, SearchLocation} from "~/types";

const searchStore = useSearch();
const locationSectionRef = ref<InstantSearchLocationSection | null>(null);
const router = useRouter();
const route = useRoute();

const isSearching = computed(() => {
  return searchStore.is_searching;
});

const currentSearchQuery = computed(
    () => {
      return searchStore.current_search_query;
    }
);

async function gotoSearchPage() {
  initiateSearch();
  await locationSectionRef.value?.adjustLocation();
  if (useRoute().name === 'classified-search') {
    await useRouter().replace({
      query: {
        q:
            currentSearchQuery.value == ""
                ? null
                : currentSearchQuery.value,
        ...searchStore.getClassifiedDefaultQueryParams(),
      },
    })
  } else {
    await navigateTo({
      name: "classified-search",
      query: {
        q:
            currentSearchQuery.value == ""
                ? null
                : currentSearchQuery.value,
        ...searchStore.getClassifiedDefaultQueryParams(),
      },
    })
  }
  doneSearching();
}

function searchBySelectedCategory(
    category
) {
  initiateSearch();
  navigateTo({
    name: "classified-search",
    query: {
      c: category.title,
      ci: category.id,
      ...searchStore.getClassifiedDefaultQueryParams(),
    },
  });
}

function searchBySelectedLocation() {
  initiateSearch();
  navigateTo({
    name: "classified-search",
    query: {
      ...route.query,
      q: currentSearchQuery.value,
      ...searchStore.getClassifiedDefaultQueryParams(),
      page: undefined,
    },
  });
}

function initiateSearch() {
  searchStore.isSearching();
  searchStore.hideSearchSuggestions();
}

function doneSearching(){
  searchStore.isNotSearching();
}

watch(
    route,
    () => {
      searchStore.isNotSearching();
    },
    {
      deep: true,
      immediate: true,
    }
);
</script>

<style></style>
