<template>
  <div
      class="flex flex-col justify-center items-center w-full m-0 p-0 z-30 sticky top-0 bg-transparent shadow"
  >
    <!-- Top Bar -->
    <div
        class="flex flex-none w-full h-20 px-0 md:px-2 lg:px-6 pt-2 bg-white"
    >
      <div
          class="flex items-center justify-evenly w-full max-w-screen-2xl mx-auto pt-2"
      >
        <!-- Brand Starts -->
        <nuxt-link
            :to="{ name: 'classified' }"
            class="flex flex-col w-1/12 pl-2 items-start justify-center cursor-pointer"
        >
          <NuxtImg
              src="/images/main/logo.png"
              alt=""
          />
          <p
              class="text-sm mx-auto font-semibold tracking-widest"
          >
            Classified
          </p>
        </nuxt-link>
        <!-- Brand Ends-->
        <!-- Buttons Section -->
        <div
            class="flex flex-row w-1/12 items-center justify-center"
        >
          <nuxt-link
              :to="{ name: 'index' }"
              class="flex flex-row items-baseline px-4 font-semibold hover:text-a-secondary focus:outline-none"
          >
            Home
          </nuxt-link>
        </div>
        <!-- Buttons Section Ends -->
        <!-- Search Section -->
        <div
            class="flex flex-row w-7/12 py-2 px-4 items-center justify-center"
        >
          <!-- Search Bar -->
          <ClassifiedInstantSearch/>
          <!-- Search Bar Ends -->
        </div>
        <!-- Search Section Ends -->
        <!-- Right Extra Section -->
        <div
            class="flex flex-row-reverse w-3/12 items-center justify-start"
        >
          <!-- User Dropdown -->
          <BaseUserDropdown
              v-if="isLoggedIn"
          />
          <!-- User Dropdown Ends -->
          <div
              class="flex flex-row items-center justify-center"
              v-else
          >
            <nuxt-link
                :to="{ name: 'login' }"
                class="mx-2 px-2 hover:text-a-secondary font-semibold"
            >
              Login
            </nuxt-link>
            <span
                class="text-2xl"
            >/</span
            >
            <nuxt-link
                :to="{ name: 'register' }"
                class="mx-2 px-2 hover:text-a-secondary font-semibold"
            >
              Register
            </nuxt-link>
          </div>
          <BaseButton
              margin-classes="mx-2"
              text-size-class="text-sm"
              is-outlined
              primary-color-class="a-primary"
              hovered-text-color-class="a-neutral-darkest"
              width-class="w-auto"
              @click.native="
              gotoCreateNewClassifiedAd
            "
          >
            <Icon
                name="mdi:plus"
            />
            New Listing
          </BaseButton>
        </div>
        <!-- Right Extra Section Ends -->
      </div>
    </div>
    <!-- Top Bar Ends-->
    <!-- Bottom Bar-->
    <ClassifiedNavCategoriesBar />
    <!-- Bottom Bar Ends-->
    <!-- Main Classified Alert -->
    <BaseAlert
        alignText="center"
        class="z-40"
    />
    <!-- Main Classified Alert Ends -->
  </div>
</template>

<script setup>
function gotoCreateNewClassifiedAd() {
  navigateTo({
    name: "dashboard-classified-create-new",
  });
}

const isLoggedIn = computed(()=>{
  return useAuthManager().isLoggedIn.value
})
</script>

<style></style>
