<template>
  <div class="flex flex-none w-full border-b-2 border-a-neutral-dim bg-white pb-1">
    <div class="md:flex items-center justify-start w-full max-w-screen-2xl h-8 mx-auto px-4 hidden">
      <!-- Categories Dropdown -->
      <BaseMultiLevelSmartDropdown
          :categories="classifiedAdCategories"
          :current-selected-category-id="currentSelectedCategoryId"
          isMultilevel
          @selectedCategory="(category) => {
                                     searchSuggestedClassifiedAdCategory(
                                       category
                                     );
                                   }
                                     "

      />
      <!-- Individual Category Tiles -->
      <p
          v-for="category in classifiedAdCategories"
          :key="category.id"
          class="font-semibold text-sm hover:text-a-secondary mx-4 cursor-pointer focus:outline-none"
          :class="currentSelectedCategoryId===category.id.toString()?'text-a-secondary':'text-a-neutral-darkest'"
          @click="
           searchSuggestedClassifiedAdCategory(
             category
           )
           "
      >
        {{ category.title }}
      </p>
      <!-- Individual Category Tiles Ends -->
      <!-- Categories Dropdown Ends-->
    </div>
    <div class="flex md:hidden w-full">

      <div
          class="swiper classifiedNavCategoriesCarousel w-full h-16 bg-white categories"
      >
        <div class="swiper-wrapper">
          <div
              class="swiper-slide font-semibold text-2xl text-center max-w-36"
              v-for="category in classifiedAdCategories"
              :key="category.id"
              @click="searchSuggestedClassifiedAdCategory(category);"
          >
            <div class="flex flex-row items-center justify-center h-full w-full">
              <p class="shadow-xl mx-1 text-xs rounded-full px-1 py-1 w-full border-2 "
              :class="currentSelectedCategoryId===category.id.toString()?'bg-a-secondary-lightest border-a-secondary text-a-secondary':'bg-white border-a-neutral-light text-a-neutral-darkest '"
              >
                {{ category.title }}</p>
            </div>
          </div>
        </div>
        <Icon
            name="mdi:chevron-left"
            class="swiper-button-prev categories-button-prev text-6xl text-a-neutral-darkest"
        />
        <Icon
            name="mdi:chevron-right"
            class="swiper-button-next categories-button-next text-6xl text-a-neutral-darkest"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
//TODO: Must add goto Categories or when category slide is pressed
import {Navigation} from "swiper/modules";

const classifiedStore = useClassified();
const searchStore = useSearch();
const classifiedAdCategories = await useClassifiedDataManager().getClassifiedAdCategories()

function searchSuggestedClassifiedAdCategory(
    category
) {
  searchStore.setSearchQuery(
      category.title
  );
  navigateTo({
    name: "classified-search",
    query: {
      c: category.title,
      ci: category.id,
      ...searchStore.getClassifiedDefaultQueryParams(),
    },
  });
}

const currentSelectedCategoryId = computed(()=>{
  const selectedCategoryId = useRoute().query.ci;
  if(selectedCategoryId){
    return selectedCategoryId.toString();
  }
  return '';
})

// }

// onMounted(() => {
//   console.log(
//     "Inside Mounted" +
//       classifiedStore.classified_ad_categories
//   );
//   if (
//     classifiedStore.classified_ad_categories ==
//       null &&
//     classifiedStore
//       .classified_ad_categories
//       .length <= 0
//   ) {
//     console.log(
//       "Going to get categories"
//     );
//     const { data, isPending, error } =
//       useFetch(
//         useNuxtApp().$getClassifiedAdCategories()
//       );

//     if (error.value != null) {
//       console.log(
//         "Categories Not Loaded" +
//           classifiedAdCategoriesResponse.responseData
//       );
//     } else {
//       console.log(data.value);
//       classifiedStore.setClassifiedAdCategories(
//         data.value
//       );
//     }
//   }
// });
// export default {
//   async fetch() {
//     if (
//       this.$store.state.classified
//         .classified_ad_categories
//         .length <= 0
//     ) {
//       let classifiedAdCategoriesResponse =

//       if (
//         !classifiedAdCategoriesResponse.ok
//       ) {
//         console.log(
//           "Categories Not Loaded" +
//             classifiedAdCategoriesResponse.responseData
//         );
//       } else {
//         this.$store.dispatch(
//           "classified/setClassifiedAdCategories",
//           classifiedAdCategoriesResponse
//             .responseData.data
//         );
//       }
//     }
//   },
// };


const swiper = ref(null);
onMounted(() => {
  swiper.value = new useNuxtApp().$swiper('.classifiedNavCategoriesCarousel', {
    modules: [Navigation],
    direction: "horizontal",
    slidesPerView: "auto",
    spaceBetween: 10,
    grabCursor: true,
    loop: false,
    navigation: {
      nextEl: `.categories-button-next`,
      prevEl: `.categories-button-prev`
    },
    effect: 'slide',
    allowTouchMove: true,
    observer: true,
  });
})
</script>

<style>
.categories-button-prev {
  @apply text-a-neutral-darkest;
}

.categories-button-next {
  @apply text-a-neutral-darkest;
}
</style>
