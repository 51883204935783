<template>
  <div
    v-click-outside="
      () => {
        searchStore.hideSearchSuggestions();
      }
    "
  >
    <!-- Search Query Input -->
    <input
      type="text"
      class="text-xs md:text-sm h-10 w-full rounded-l bg-white border border-a-neutral-dim border-r-0 p-2 focus:outline-none font-semibold"
      placeholder="Search For Items..."
      v-model="localSearchQuery"
      @input="getInstantSearchResults"
    />
    <!-- Search Query Input Ends -->
    <!-- Search Suggestions -->
    <div
      :class="[
        'flex flex-col w-full absolute top-10 bg-white border-b-2 border-l-2 border-r-2 rounded-b shadow-xl z-40',
      ]"
      v-if="viewSearchSuggestionsList"
    >
      <BaseInstantSearchSuggestionsList
        v-if="
          classifiedAdCategorySearchSuggestions.length >
          0
        "
        :suggestions="
          classifiedAdCategorySearchSuggestions
        "
        highlighting-field-name="title"
        list-title="Categories"
        :current-search-query="
          localSearchQuery
        "
        @suggestionSelected="
          (
            selectedCategorySuggestion
          ) =>
            searchSuggestedClassifiedAdCategory(
              selectedCategorySuggestion
            )
        "
      />
      <span
        class="w-full border-b border-a-neutral-dim"
      ></span>
      <BaseInstantSearchSuggestionsList
        v-if="
          classifiedAdSearchSuggestions.length >
          0
        "
        :suggestions="
          classifiedAdSearchSuggestions
        "
        highlighting-field-name="title"
        list-title="Classified Listings"
        :current-search-query="
          localSearchQuery
        "
        @suggestionSelected="
          (selectedListingSuggestion) =>
            gotoSuggestedClassifiedAdPage(
              selectedListingSuggestion
            )
        "
      />
    </div>
    <!-- Search Suggestions Div Ends -->
  </div>
</template>

<script setup>
const searchStore = useSearch();
const route = useRoute();

const emit = defineEmits([
  "searchBySelectedCategory",
]);

const localSearchQuery = ref("");
const classifiedAdCategorySearchSuggestions =
  ref([]);
const classifiedAdSearchSuggestions =
  ref([]);

const viewSearchSuggestionsList =
  computed(() => {
    return (
      searchStore.is_showing_search_suggestions &&
      (classifiedAdCategorySearchSuggestions
        .value.length > 0 ||
        classifiedAdSearchSuggestions
          .value.length > 0)
    );
  });

watch(localSearchQuery, (newValue) => {
  if (localSearchQuery.value === "") {
    searchStore.hideSearchSuggestions();
  }
  searchStore.setSearchQuery(newValue);
});

watch(
  () => route.query,
  () => {
    localSearchQuery.value =
      searchStore.current_search_query;
  }
);

function searchSuggestedClassifiedAdCategory(
  category
) {             
  searchStore.hideSearchSuggestions();
  localSearchQuery.value =
    category.title;
  searchStore.isSearching();
  emit(
    "searchBySelectedCategory",
    category
  );
}

async function getInstantSearchResults() {
  if (
      localSearchQuery.value !== "" &&
      localSearchQuery.value.length > 1
  ) {
    searchStore.showSearchSuggestions();
    const results = await useMainClassifiedDataManager().getClassifiedAdInstantSearchResults(
        localSearchQuery.value
    );
    if (results) {
      classifiedAdCategorySearchSuggestions.value =
          results.classified_ad_categories_results;
      classifiedAdSearchSuggestions.value =
          results.classified_ad_results;
    }      
  }
}

function gotoSuggestedClassifiedAdPage(classifiedAd) {
  localSearchQuery.value = "";
  navigateTo({
    name: "classified-slug",
    params: {
      slug: classifiedAd.slug,
    },
  });
}

onMounted(() => {
  if (
    searchStore.current_search_query
  ) {
    localSearchQuery.value =
      searchStore.current_search_query;
  } else {
    route.query.q != null
      ? (localSearchQuery.value =
          route.query.q)
      : (localSearchQuery.value =
          route.query.c);
  }
})
</script>

<style></style>
